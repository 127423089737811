const m = require('mithril')
const data = require('../lib/data')

module.exports = {
  _loadItem (col, id) {
    return data.dataQuery({ model: col.model, query: { _id: { $eq: id } } })
      .then((res) => {
        this.item = res.data.dataQuery[0]
        this.list = this.extractor(this.item)
        m.redraw()
      })
  },
  onremove () {
  },
  oninit (vnode) {
    this.item = null
    this.list = []
    this.col = vnode.attrs.cols[vnode.attrs.collection]
    this.id = vnode.attrs.loadId
    this.extractor = vnode.attrs.extractor
    this._loadItem(this.col, this.id)
  },
  view (vnode) {
		if (!this.list?.length) return m('span', { style: 'color: silver' }, '(null)')
    const text = this.list.join(', ')
    return m('p', {}, text)
  }
}
