const m = require('mithril')
const { nullDef, emptyDef, renderers } = require('../lib/table')
const control = require('../lib/control')
const Colored = require('../components/colored')
const yaml = require('js-yaml')

const ShiftsModel = (col = 'shifts', model = 'shift', title = 'Shifts', aliases = ['s']) => {
  return {
    title,
    model,
    aliases,
    preview: s => `${renderers.periodPart('start', false)(null, s)} - ${renderers.periodPart('end', false, true)(null, s)}`,
    edit: {
      schema: {
        properties: {
          userId: {
            type: 'string'
          }
        }
      }
    },
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink(col, true) },
      { name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      { name: 'user id', key: 'userId', render: renderers.link('users', false, true) },
      { name: 'duration', key: '_duration', render: renderers.shiftDuration },
      { name: 'start', key: '_period_start', render: renderers.periodPart('start') },
      { name: 'end', key: '_period_end', render: renderers.periodPart('end') },
      { name: 'announced', key: 'announced', render: renderers.boolean },
      { name: 'deleted', key: 'deleted', render: renderers.boolean },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      { name: 'user id', key: 'userId', render: renderers.link('users', false, true) },
      { name: 'position id', key: 'positionId', render: renderers.default },
      { name: 'ideal skill', key: 'idealSkill', render: renderers.default },
      { name: 'start', key: '_period_start', render: renderers.periodPart('start') },
      { name: 'end', key: '_period_end', render: renderers.periodPart('end') },
      {
        name: 'pauses',
        key: 'pauses',
        render: renderers.array([
          { name: 'start', key: 'start' },
          { name: 'duration', key: 'duration' }
        ])
      },
      { name: 'duration', key: '_duration', render: renderers.shiftDuration },
      {
        name: 'revisions',
        key: 'revisions',
        render: renderers.array([
          { name: 'id', key: '_id' },
          { name: 'type', key: 'type' },
          { name: 'diff', key: 'diff', render: renderers.yamlDumpFull },
          { name: 'author', key: 'author', render: renderers.link('users', false, true) },
          { name: 'created', key: 'created', render: renderers.date }
        ])
      },
      {
        name: 'announces',
        key: 'announces',
        render: renderers.array([
          { name: 'id', key: '_id' },
          { name: 'revisionId', key: 'revisionId' },
          { name: 'author', key: 'author', render: renderers.link('users', false, true) },
          { name: 'created', key: 'created', render: renderers.date }
        ])
      },
      { name: 'announced', key: 'announced', render: renderers.boolean },
      { name: 'deleted', key: 'deleted', render: renderers.boolean },
      { name: 'created', key: 'created', render: renderers.dateFull }
    ],
    connectedKey: 'shiftId',
    connected: [
      {
        id: 'offers',
        name: 'Offers',
        col: 'offers'
      }
    ]
  }
}

module.exports = {
  workspaces: {
    title: 'Workspaces',
    model: 'workspace',
    textId: true,
    aliases: [
      'ws',
      'w'
    ],
    objectId: false,
    keywords: [
      'name',
      'organizationId',
    ],
    preview: x => x.name,
    edit: {
      schema: {
        properties: {
          name: {
            type: 'string'
          },
          country: {
            type: 'string'
          },
          language: {
            type: 'string'
          },
          timezone: {
            type: 'string'
          },
          owner: {
            type: 'string'
          },
          calendarLockedUntil: {
            type: 'date'
          }
        }
      }
    },
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink('workspaces', false, true) },
      { name: 'name', key: 'name', render: val => m('b', renderers.maxLength(val)) },
      { name: 'country', key: 'country' },
      { name: 'organization id', key: 'organizationId', render: renderers.link('organizations', false, true) },
      { name: 'timezone', key: 'timezone', render: renderers.small },
      { name: 'language', key: 'language' },
      { name: 'roles', key: '_roles', align: 'center', render: renderers.asyncCount('role', 'workspaceId', { archived: { $ne: true } }) },
      { name: 'plugins', key: '_plugins', align: 'center', render: (_, item) => item && item.plugins ? item.plugins.length : nullDef },
      { name: 'positions', key: '_positions', align: 'center', render: (_, item) => item.positions ? item.positions.filter(p => !p.archived).length : nullDef },
      { name: 'cycles', key: '_cycles', align: 'center', render: (_, item) => item && item.cycles ? item.cycles.length : nullDef },
      { name: 'localities', key: '_localities', align: 'center', render: (_, item) => item.localities ? item.localities.length : nullDef },
      { name: 'templates', key: '_templates', align: 'center', render: (_, item) => item.templates ? item.templates.length : nullDef },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'organization id', key: 'organizationId', render: renderers.link('organizations', false, true) },
      { name: 'name', key: 'name', render: renderers.nameBig },
      { name: 'country', key: 'country' },
      { name: 'paid', key: 'subscription', render: s => renderers.boolean(s ? s.paid : false) },
      { name: 'deactivated', key: 'subscription', render: s => renderers.boolean(s ? s.deactivated : false) },
      { name: 'timezone', key: 'timezone' },
      { name: 'language', key: 'language' },
      {
        name: 'plugins',
        key: 'plugins',
        render: renderers.array([
          { name: 'id', key: '_id', render: val => m('small', val) },
          { name: 'plugin', key: 'plugin' },
          { name: 'enabled', key: 'enabled', render: renderers.boolean }
        ])
      },
      {
        name: 'positions',
        key: 'positions',
        filter: (p) => !p.archived,
        render: renderers.array([
          { name: 'id', key: '_id', render: val => m('small', val) },
          { name: 'name', key: 'name' },
          { name: 'assigns', key: 'assigns', render: val => val.length },
          { name: 'created', key: 'created', render: renderers.date }
        ])
      },
      {
        name: 'localities',
        key: 'localities',
        render: renderers.array([
          { name: 'id', key: '_id', render: val => m('small', val) },
          { name: 'name', key: 'name' },
          { name: 'shortName', key: 'shortName' },
          { name: 'address', key: 'address' },
          { name: 'created', key: 'created', render: renderers.date }
        ])
      },
      {
        name: 'cycles',
        key: 'cycles',
        render: renderers.array([
          { name: 'id', key: '_id', render: val => m('small', val) },
          { name: 'title', key: 'title' },
          { name: 'startDate', key: 'startDate', render: renderers.date },
          { name: 'created', key: 'created', render: renderers.date }
        ])
      },
      {
        name: 'templates',
        key: 'templates',
        render: renderers.array([
          { name: 'id', key: '_id', render: val => m('small', val) },
          { name: 'name', key: 'name' },
          { name: 'type', key: 'type' },
          { name: 'created', key: 'created', render: renderers.date }
        ])
      },
      { name: 'owner', key: 'owner', render: renderers.link('users', false, true) },
      { name: 'created', key: 'created', render: renderers.dateFull },
      { name: 'calendar locked until', key: 'calendarLockedUntil', editKey:'calendarLockedUntil',render: val => val ? renderers.formatedDate(val ,'yyyy-MM-dd') : null }
    ],
    connectedKey: 'workspaceId',
    connected: [
      {
        id: 'roles',
        name: 'Roles',
        col: 'roles'
      },
      {
        id: 'offers',
        name: 'Offers',
        col: 'offers'
      },
      {
        id: 'shifts',
        name: 'Shifts',
        col: 'shifts'
      },
      {
        id: 'events',
        name: 'Events',
        col: 'events'
      },
      {
        id: 'timeoffs',
        name: 'Timeoffs',
        col: 'timeoffs'
      },
      {
        id: 'availabilities',
        name: 'Availabilities',
        col: 'availabilities'
      },
      {
        id: 'plans',
        name: 'Plans',
        col: 'plans'
      },
      {
        id: 'auditlogs',
        name: 'Audit logs',
        col: 'auditlogs',
        filterCols: c => c.key !== 'target',
        query: (id, item) => ({ type: 'workspace', target: id })
      }
    ],
    tools: {
      migrate: {
        title: 'Migrate workspace',
        component: {
          oninit: (vnode) => {
            this.env = 'stage'
            this.result = null
            this.loading = null
            this.force = false
            this.setEnv = (e) => { this.env = e.target.value }
            this.switchForce = () => { this.force = !this.force }
            this.clickButton = () => {
              this.loading = true
              this.result = null
              control.workspaceMigrate({ id: vnode.attrs.item._id, env: this.env, force: this.force })
                .then(res => {
                  const out = res.data.workspaceMigrate
                  this.loading = false
                  this.result = out ? (out.data ? out.data : out) : res
                })
            }
          },
          view: (vnode) => {
            if (!vnode.attrs.config || !vnode.attrs.config.core) {
              return m('div', 'Loading ...')
            }
            return m('div', [
              m('.level', [
                m('.level-left', [
                  m('.level-item', 'Target:'),
                  m('.level-item.select', [
                    m('select', { value: this.env, onchange: this.setEnv },
                      vnode.attrs.config.core.env.linked.map(le => {
                        return m('option', { value: le.env }, le.env)
                      }))
                  ]),
                  m('.level-item', [
                    m('label.checkbox', [
                      m('input[type="checkbox"]', { style: 'display: inline-block; margin-right: 5px;', value: this.force, onchange: this.switchForce }),
                      'Force'
                    ])
                  ]),
                  m('.level-item', [
                    m('button.button.is-link', { onclick: this.clickButton }, 'Run migration')
                  ])
                ])
              ]),
              this.loading ? m('div', 'Migrating ..') : null,
              this.result ? m(Colored, { text: yaml.safeDump(this.result) }) : null
            ])
          }
        }
      },
    }
  },
  users: {
    title: 'Users',
    model: 'user',
    textId: true,
    aliases: [
      'u'
    ],
    objectId: false,
    keywords: [
      'name',
      'email',
      'firstName',
      'lastName'
    ],
    preview: (item) => item ? [item.firstName, item.lastName].join(' ') + ` (${item.email})` : null,
    edit: {
      schema: {
        type: 'object',
        properties: {
          firstName: {
            type: 'string'
          },
          lastName: {
            type: 'string'
          },
          email: {
            type: 'string',
            format: 'email'
          },
          telephone: {
            type: 'string'
          },
          dummy: {
            type: 'string'
          },
          language: {
            type: 'string'
          },
          password: {
            type: 'string'
          }
        }
      }
    },
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink('users', false, true) },
      { name: 'name', key: '_name', render: renderers.name },
      { name: 'email', key: 'email', render: val => m('small', val) },
      { name: 'dummy', key: 'dummy', render: renderers.boolean },
      { name: 'language', key: 'language', render: renderers.default },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'name', key: 'name', render: renderers.nameBig },
      { name: 'first name', key: 'firstName', render: renderers.default },
      { name: 'last name', key: 'lastName', render: renderers.default },
      { name: 'email', key: 'email', render: val => m('a', { href: `mailto:${val}` }, val) },
      { name: 'language', key: 'language' },
      { name: 'dummy', key: 'dummy', render: renderers.boolean },
      { name: 'enabled', key: 'enabled', render: renderers.boolean },
      { name: 'password', key: 'password', render: renderers.password },
      { name: 'created', key: 'created', render: renderers.dateFull }
    ],
    connectedKey: 'userId',
    connected: [
      {
        id: 'roles',
        name: 'Roles',
        col: 'roles',
        sort: { archived: 1, created: -1 }
      },
      {
        id: 'offers_author',
        name: 'Offers (author)',
        col: 'offers',
        query: id => ({ authorId: id })
      },
      {
        id: 'offers_candidate',
        name: 'Offers (candidate)',
        col: 'offers',
        find: (id, d) => {
          return d.dataQuery({ model: 'requiredaction', limit: 50, query: { type: 'offerAnnounce', 'target.userIds': id }}, true)
              .then(({ data: { dataQuery: actions, dataQueryCount: count } }) => {
                return d.dataQuery({
                  model: 'offer',
                  query: { 'announceActionId': { $in: actions.map(a => a._id) } }
                }).then(({ data: { dataQuery: offers } }) => { return [count, offers] })
              })

        }
      },
      {
        id: 'shifts',
        name: 'Shifts',
        col: 'shifts'
      },
      {
        id: 'timeoffs',
        name: 'Timeoffs',
        col: 'timeoffs'
      },
      {
        id: 'availabilities',
        name: 'Availabilities',
        col: 'availabilities'
      },
      {
        id: 'auditlogs',
        name: 'Audit Logs',
        col: 'auditlogs'
      }
    ],
    tools: {
      loginAs: {
        title: 'Login as this user',
        component: {
          oninit:  (vnode) => {
            this.token = null
            this.clickButton = () => {
              control.userSession({id: vnode.attrs.item._id})
                .then(res => {
                  if(!this.token) this.token = res.data.userSession.token
                  window.open(`${vnode.attrs.env.fe[0]}/auth/${this.token}`)
                })
            }
            this.generateToken = () => {
              control.userSession({id: vnode.attrs.item._id})
                .then(res => {
                  this.token = res.data.userSession.token
                })
            }
          },
          view: (vnode) => {
            return vnode.attrs.item ? m('div',{ style: 'display:flex; flex-direction:column;' }, [
              m('button.button.is-link', { style: 'width:max-content;', onclick: this.clickButton }, `Log in as ${vnode.attrs.item.email}`),
              m('button.button.is-link', { style: 'width:max-content;margin-top:1rem;', onclick: this.generateToken }, `Generate token`),
              this.token ? m('div',{ style: 'width:50%;margin-top:1rem;word-break: break-all;'}, [`${this.token}`]) : null,
            ]) : ''
          }
        }
      }
    }
  },
  roles: {
    title: 'Roles',
    model: 'role',
    textId: false,
    aliases: [
      'r',
      'role',
      'roles'
    ],
    objectId: true,
    keywords: ['role', 'roles', 'employeeId'],
    preview: item => item && item.customData ? [item.customData.firstName, item.customData.lastName].join(' ') + ` (${item._id})` : null,
	edit: {
	  schema: {
		type: 'object',
		properties: {
		  hidden: {
			type: 'boolean'
		  }
		}
	  }
	},
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink('roles', false, true) },
      {
        name: 'accessroles',
        key: 'workspaceId',
        render: renderers.asyncList('workspaces', function (role) {
          return function (workspace) {
	          return workspace?.accessRoles.filter(ar => ar.assigns.find(a => a.userId === role.userId)).map(ar => ar.name)
          }
        })
      },
      { name: 'employeeId', key: 'customData', render: val => val ? val.employeeId : null },
      { name: 'user', key: 'userId', render: renderers.link('users', false, true) },
      { name: 'workspace', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
	  {
		name: 'locality',
		key: 'workspaceId',
		render: renderers.asyncList('workspaces', function (role) {
		  return function (workspace) {
			return workspace?.plugins.find(p => p.plugin === 'localities')?.enabled ? workspace.localities.filter(l => l.assigns.find(a => a.userId === role.userId)).map(l => l.name) : null
		  }
		})
	  },
      { name: 'organization', key: 'organizationId', render: renderers.link('organizations', false, true) },
      { name: 'archived', key: 'archived', render: renderers.boolean },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      {
        name: 'accessroles',
        key: 'workspaceId',
        render: renderers.asyncList('workspaces', function (role) {
          return function (workspace) {
            return workspace.accessRoles.filter(ar => ar.assigns.find(a => a.userId === role.userId)).map(ar => ar.name)
          }
        })
      },
      { name: 'user', key: 'userId', render: renderers.link('users', false, true) },
      { name: 'workspace', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      { name: 'organization', key: 'organizationId', render: renderers.link('organizations', false, true) },
      { name: 'first name', key: 'customData', render: val => val ? val.firstName : null },
      { name: 'last name', key: 'customData', render: val => val ? val.lastName : null },
      { name: 'email', key: 'customData', render: val => val && val.email ? m('a', { href: `mailto:${val.email}` }, val.email) : null },
      { name: 'employeeId', key: 'customData', render: val => val ? val.employeeId : null },
      { name: 'archived', key: 'archived', render: renderers.boolean },
	  { name: 'hidden', key: 'hidden', render: renderers.boolean },
      { name: 'created', key: 'created', render: renderers.dateFull },
      {
        name: 'contracts',
        key: 'contracts',
        render: renderers.array([
          { name: 'id', key: '_id', render: val => m('small', val) },
          { name: 'contractId', key: 'contractId' },
          { name: 'type', key: 'type' },
          { name: 'minutes per month', key: 'options', render: val => val ? val.minutesOfWorkPerMonth : null },
          { name: 'minutes per shift', key: 'options', render: val => val ? val.minutesOfWorkPerShift : null },
          { name: 'start', key: 'period', render: val => val ? renderers.dateFull(val.start) : null },
          { name: 'end', key: 'period', render: val => val ? renderers.dateFull(val.end) : null },
          { name: 'created', key: 'created', render: renderers.dateFull }
        ])
      },
    ]
  },
  organizations: {
    title: 'Organizations',
    model: 'organization',
    textId: true,
    objectId: false,
	  preview: x => x.name,
    edit: {
      schema: {
      type: 'object',
          properties: {
            settings:{
              billing:{
                details:{
                  companyName: {
                    type: 'string'
                  },
                  street: {
                    type: 'string'
                  },
                  city: {
                    type: 'string'
                  },
                  zipCode: {
                    type: 'string'
                  },
                  regNumber: {
                    type: 'string',
                  },
                  vatPayer: {
                    type: 'boolean'
                  },
                  billingEmail: {
                    type: 'string'
                  }
                },
                paid: {
                  type: 'boolean'
                },
                trial: {
                  type: 'boolean'
                },
                uolId: {
                  type: 'string'
                },
                countUsersType: {
                  type: 'string'
                },
                trialRequest: {
                  type: 'boolean'
                },
                helpRequest: {
                  type: 'boolean'
                },
                expiration: {
                  type: 'date'
                },
                mode: {
                  type: 'string'
                },
                modeDate: {
                  type: 'string'
                },
                dueDatePeriod: {
                  type: 'int'
                },
                dueDate: {
                  type: 'date'
                },
                perUser: {
                  type: 'int',
                },
                perPlugin: {
                  type: 'int'
                },
                perSla: {
                  type: 'int'
                },
                sale: {
                  type: 'int'
                },
                fixedPay: {
                  type: 'int'
                },
                subSla: {
                  type: 'array'
                }
              }
            }
          }
      }
    },
    aliases: [
      'o',
      'org',
      'orgs'
    ],
    keywords: [
      'name'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink('organizations', false, true) },
      { name: 'name', key: 'name', render: val => m('b', renderers.default(val)) },
      { name: 'workspaces', key: 'workspaces', render: val => val ? val.length : null },
      { name: 'created', key: 'created', render: renderers.date },
      { name: 'expiration', key: 'settings', editKey:'settings.billing.expiration',render: val => val.billing ? renderers.formatedDate(val.billing.expiration,'yyyy-MM-dd') : null },
      { name: 'paid', key: 'settings', editKey:'settings.billing.paid',render: val => val.billing ? m('div', [
          m('small',  val.billing.paid && !val.billing.trial ? {style: 'color: green' } : !val.billing.trial  ? {style: 'color: red'} : {},renderers.boolean(val.billing.paid))
        ]): null},
      { name: 'trial', key: 'settings', editKey:'settings.billing.trial',render: val => val.billing ? m('div', [
          m('small',  val.billing.trial && !val.billing.paid ? {style: 'color: green' } : !val.billing.paid  ? {style: 'color: red'} : {},renderers.boolean(val.billing.trial))
        ]): null},
      { name: 'trialRequest', key: 'settings', editKey:'settings.billing.trialRequest',render: val => val.billing ? m('div', [
          m('small',  val.billing.trialRequest && !val.billing.paid && !val.billing.trial? {style: 'color: green' } : !val.billing.paid && !val.billing.trial ? {style: 'color: red'} : {},renderers.boolean(val.billing.trialRequest))
        ]): null},
      { name: 'status', key: 'settings',render: val => val.billing ? m('div', [
          val.billing.trialRequest ? m('i.fas. fa-exclamation-circle', {style:'margin-left: 0.5rem'}) : null ,
          !val.billing.paid && !val.billing.trial ? m('i.fas.  fa-lock', {style:'margin-left: 0.5rem'}) : null ,
          val.billing.paid || val.billing.trial ? m('i.fas.   fa-check-circle', {style:'margin-left: 0.5rem'}) : null ,
        ]): null},
      { name: 'modeDate', key: 'settings', editKey:'settings.billing.modeDate',render: val => val.billing ? renderers.formatedDate(val.billing.modeDate,'yyyy-MM-dd') : null },
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'name', key: 'name', render: renderers.default },
	    { name: 'employee count', key: 'employeeCount', render: (val, item) => renderers.asyncValue('usage', 'value', { organizationId: item._id, type: 'employeeCount' })() },
      { name: 'paid', key: 'settings', editKey:'settings.billing.paid',render: val => val.billing ? renderers.boolean(val.billing.paid) : null },
      { name: 'trial', key: 'settings', editKey:'settings.billing.trial',render: val => val.billing ? renderers.boolean(val.billing.trial) : null },
      { name: 'uolId', key: 'settings', editKey:'settings.billing.uolId',render: val =>
          val.billing ?
            m('div', [
              val.billing.uolId,
              m('small',  { style: 'color: silver;' },' (internal contact id in UOL)')
            ]) : null
      },
      { name: 'trialRequest', key: 'settings', editKey:'settings.billing.trialRequest',render: val => val.billing ? renderers.boolean(val.billing.trialRequest)  : null },
      { name: 'helpRequest', key: 'settings', editKey:'settings.billing.helpRequest',render: val => val.billing ? renderers.boolean(val.billing.helpRequest) : null },
      { name: 'expiration', key: 'settings', editKey:'settings.billing.expiration',render: val =>
          val.billing ?
            m('div', [
              renderers.formatedDate(val.billing.expiration,'yyyy-MM-dd'),
              m('small',  { style: 'color: silver;' },' (next billing date)')
            ]) : null
      },
      { name: 'mode', key: 'settings', editKey:'settings.billing.mode',render: val =>
          val.billing ?
          m('div', [
            val.billing.mode,
            m('small',  { style: 'color: silver;' },' (contract renewal period, options: monthly,custom,annually)')
          ]) : null
      },
      { name: 'modeDate', key: 'settings', editKey:'settings.billing.modeDate',render: val =>
          val.billing ?
            m('div', [
              renderers.formatedDate(val.billing.modeDate,'yyyy-MM-dd'),
              m('small',  { style: 'color: silver;' },' (contract renewal date)')
            ]) : null
      },
      { name: 'dueDatePeriod', key: 'settings', editKey:'settings.billing.dueDatePeriod',render: val =>
          val.billing ?
            m('div', [
              val.billing.dueDatePeriod,
              m('small',  { style: 'color: silver;' },' (number of days of due date)')
            ]) : null
      },
      { name: 'dueDate', key: 'settings', editKey:'settings.billing.dueDate',render: val =>
          val.billing ?
            m('div', [
              renderers.formatedDate(val.billing.dueDate,'yyyy-MM-dd'),
              m('small',  { style: 'color: silver;' },' (invoice due date)')
            ]) : null
      },
      { name: 'perUser', key: 'settings', editKey:'settings.billing.perUser',render: val => val.billing ?  val.billing.perUser : null},
      { name: 'perPlugin', key: 'settings', editKey:'settings.billing.perPlugin',render: val => val.billing ?  val.billing.perPlugin : null},
      { name: 'perSla', key: 'settings', editKey:'settings.billing.perSla',render: val => val.billing ?  val.billing.perSla : null},
      { name: 'sale', key: 'settings', editKey:'settings.billing.sale',render: val => val.billing ?  val.billing.sale : null},
      { name: 'fixedPay', key: 'settings', editKey:'settings.billing.fixedPay',render: val => val.billing ?  val.billing.fixedPay : null},
      { name: 'company name', key: 'settings', editKey:'settings.billing.details.companyName',render: val => val.billing && val.billing.details && val.billing.details.companyName  ?  val.billing.details.companyName : null},
      { name: 'city', key: 'settings', editKey:'settings.billing.details.city',render: val => val.billing && val.billing.details && val.billing.details.city ?  val.billing.details.city : null},
      { name: 'street', key: 'settings', editKey:'settings.billing.details.street',render: val => val.billing && val.billing.details && val.billing.details.street ?  val.billing.details.street : null},
      { name: 'zip code', key: 'settings', editKey:'settings.billing.details.zipCode',render: val => val.billing && val.billing.details && val.billing.details.zipCode ?  val.billing.details.zipCode : null},
      { name: 'vat payer', key: 'settings', editKey:'settings.billing.details.vatPayer',render: val => val.billing && val.billing.details ?  renderers.boolean(val.billing.details.vatPayer) : null},
      { name: 'billing email', key: 'settings', editKey:'settings.billing.details.billingEmail',render: val => val.billing && val.billing.details && val.billing.details.billingEmail ?  val.billing.details.billingEmail : null},
      { name: 'regNumber', key: 'settings', editKey:'settings.billing.details.regNumber',render: val => val.billing && val.billing.details && val.billing.details.regNumber ?  val.billing.details.regNumber : null},
      { name: 'Sla', key: 'settings', editKey:'settings.billing.subSla',render: val =>
          val.billing ?
            m('div', [
            val.billing.subSla.map( sla => {
              return m('li', sla)
            }),
            m('small',  { style: 'color: silver;' },' (separator: ; or , values: chat,email,phone,insurance)')
          ]) : null
         },
      { name: 'countUsersType', key: 'settings', editKey:'settings.billing.countUsersType',render: val =>
          val.billing ?
            m('div', [
              val.billing.countUsersType,
              m('small',  { style: 'color: silver;' },' (values: all,uniq,active)')
            ]) : null
      },
      {
        name: 'workspaces',
        key: 'workspaces',
        render: renderers.array([
          { name: 'id', key: 'id', render: renderers.link('workspaces', false, true) },
        ])
      },
      { name: 'created', key: 'created', render: renderers.dateFull },
      {
        name: 'countries', key: 'countries', render: (val => {
          const toRender = [];
          for (const country of val) {
            const areas = country.areas.map(a => {
              return { name: a.name, workspaces: a.workspaces }
            })
            toRender.push(m('div', { style: 'margin-bottom: 2em;' }, [
              m('u', {style: "font-weight: bold;"}, `Country: ${country._id} (${country.code})`),
              m('div', {style: "padding-left: 2em; padding-top: 1em;"}, areas.map(a => {
                return m('div', {style: "margin-bottom: 1em;"}, [
                  m('b', a.name),
                  m('p', a.workspaces.length > 0 ? a.workspaces.join(',') : '-'),
                ])
              }))
            ]))
          }
          return toRender
        })
      },
    ],
    tools: {
      migrate: {
        title: 'Organization workspace',
        component: {
          oninit: (vnode) => {
            this.env = 'stage'
            this.result = null
            this.loading = null
            this.force = false
            this.setEnv = (e) => {
              this.env = e.target.value
            }
            this.switchForce = () => {
              this.force = !this.force
            }
            this.clickButton = () => {
              this.loading = true
              this.result = null
              control.organizationMigrate({id: vnode.attrs.item._id, env: this.env, force: this.force})
                .then(res => {
                  const out = res.data.organizationMigrate
                  this.loading = false
                  this.result = out ? (out.data ? out.data : out) : res
                })
            }
          },
          view: (vnode) => {
            if (!vnode.attrs.config || !vnode.attrs.config.core) {
              return m('div', 'Loading ...')
            }
            return m('div', [
              m('.level', [
                m('.level-left', [
                  m('.level-item', 'Target:'),
                  m('.level-item.select', [
                    m('select', {value: this.env, onchange: this.setEnv},
                      vnode.attrs.config.core.env.linked.map(le => {
                        return m('option', {value: le.env}, le.env)
                      }))
                  ]),
                  m('.level-item', [
                    m('label.checkbox', [
                      m('input[type="checkbox"]', {
                        style: 'display: inline-block; margin-right: 5px;',
                        value: this.force,
                        onchange: this.switchForce
                      }),
                      'Force'
                    ])
                  ]),
                  m('.level-item', [
                    m('button.button.is-link', {onclick: this.clickButton}, 'Run migration')
                  ])
                ])
              ]),
              this.loading ? m('div', 'Migrating ..') : null,
              this.result ? m(Colored, {text: yaml.safeDump(this.result)}) : null
            ])
          }
        }
      },
	    mfaUsage: {
		    title: 'Mfa Usage',
		    component: {
			    oninit: (vnode) => {
				    this.options = ['last', 'this']
				    this.selectedOption = this.options[1]
				    this.mfaResult = null
				    this.mfaLoading = null
				    this.switchOption = () => {
					    this.selectedOption = this.selectedOption === this.options[0] ? this.options[1] : this.options[0]
				    }
				    this.clickButtonMfa = () => {
					    this.mfaLoading = true
					    this.mfaResult = null
					    control.mfaUsage({ organizationId: vnode.attrs.item._id, month: this.selectedOption })
						    .then(res => {
							    const out = res.data.organizationMigrate
							    this.mfaLoading = false
							    this.mfaResult = out ? (out.data ? out.data : out) : res.data.mfaUsage
						    })
				    }
			    },
			    view: (vnode) => {
				    if (!vnode.attrs.config || !vnode.attrs.config.core) {
					    return m('div', 'Loading ...')
				    }
				    return m('div', [
					    m('.level', [
						    m('.level-left', [
							    m('.level-item', 'Target month:'),
							    m('.level-item.select', [
								    m('select', { value: this.selectedOption, onchange: this.switchOption },
									    this.options.map(o => {
										    return m('option', { value: o }, o)
									    }))
							    ]),
							    m('.level-item', [
								    m('button.button.is-link', { onclick: this.clickButtonMfa }, 'Load MFA usage')
							    ])
						    ])
					    ]),
					    this.mfaLoading ? m('div', 'Loading ..') : null,
					    this.mfaResult ? m(Colored, { text: yaml.safeDump(this.mfaResult) }) : null
				    ])
			    }
		    }
	    },
	    dsProToggle: {
		    title: 'DS PRO',
		    component: {
			    oninit: (vnode) => {
				    this.toggleLoading= null
				    this.toggleDsPro = () => {
					    this.toggleLoading = true
					    // Even though the value for hidden is the opposite, we send this value to make more sense in the query
					    const value = vnode.attrs.item.hidden
					    control.toggleDsPro({ id: vnode.attrs.item._id, value, ownerEmail: this.ownerEmail })
						    .then(() => {
							    this.toggleLoading = false
							    this.ownerEmail = null
							    vnode.attrs.item.hidden = !vnode.attrs.item.hidden
						    })
				    }
			    },
			    view: (vnode) => {
				    if (!vnode.attrs.config || !vnode.attrs.config.core) {
					    return m('div', 'Loading ...')
				    }
				    return m('div', [
					    m('.level', [
						    m('.level-left', [
							    m('.level-item', [
										m('text', `DS PRO: ${vnode.attrs.item.hidden ? 'OFF' : 'ON'}`),
							    ]),
							    vnode.attrs.item.hidden ? m('input.input', { required: true, type: 'text', placeholder: 'Owner email...', onchange: (e) => {
										console.log(e.currentTarget.value)
									    this.ownerEmail = e.currentTarget.value
								    } }) : null,
							    m('.level-item', [
								    m('button.button.is-link', { onclick: this.toggleDsPro, disabled: (this.toggleLoading || (vnode.attrs.item.hidden && !this.ownerEmail?.length)) }, vnode.attrs.item.hidden ? 'Turn on' : 'Turn off')
							    ])
						    ])
					    ]),
					    this.toggleLoading ? m('div', 'Loading ..') : null,
				    ])
			    }
		    }
	    },
    }
  },
  shifts: ShiftsModel(),
  uncommittedshifts: ShiftsModel('uncommittedshifts', 'uncommittedshift', 'Shifts [uncommitted]', ['us']),
  deletedshifts: ShiftsModel('deletedshifts', 'deletedshift', 'Shifts [deleted]', ['ds']),
  events: {
    title: 'Events',
    model: 'event',
    aliases: [
      'ev'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink('events', true) },
      { name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      { name: 'type', key: 'type', render: renderers.default },
      { name: 'start', key: '_period_start', render: renderers.periodPart('start') },
      { name: 'end', key: '_period_end', render: renderers.periodPart('end') },
      { name: 'title', key: 'title', render: renderers.default },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      { name: 'type', key: 'type', render: renderers.default },
      { name: 'start', key: '_period_start', render: renderers.periodPart('start') },
      { name: 'end', key: '_period_end', render: renderers.periodPart('end') },
      { name: 'title', key: 'title', render: renderers.default },
      { name: 'created', key: 'created', render: renderers.dateFull }
    ]
  },
	worktimeevents: {
		title: 'Work Time Events',
		model: 'worktimeevent',
		aliases: [
			'wte'
		],
		cols: [
			{ name: 'id', key: '_id', render: renderers.simpleLink('worktimeevents', true) },
			{ name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
			{ name: 'user id', key: 'userId', render: renderers.link('users', false, true) },
			{ name: 'event type', key: 'eventType', render: renderers.default},
			{ name: 'date', key: 'date', render: renderers.date },
			{ name: 'created', key: 'created', render: renderers.date }
		],
		detail: [
			{ name: 'id', key: '_id', render: renderers.simpleLink('worktimeevents', true) },
			{ name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
			{ name: 'user id', key: 'userId', render: renderers.link('users', false, true) },
			{ name: 'event type', key: 'eventType', render: renderers.default},
			{ name: 'event id', key: 'eventId', render: ((val, item) => {
				switch (item.eventType) {
					case 'shift':
						return renderers.link('shifts', false, true)(val);
					case 'timeOff':
						return renderers.link('timeoffs', false, true)(val);
					default:
						return renderers.default(val);
				}
			})},
			{ name: 'date', key: 'date', render: renderers.date },
			{ name: 'created', key: 'created', render: renderers.date }
		]
	},
  commits: {
    title: 'Commit',
    model: 'commit',
    aliases: [
      'co'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink('commits', true) },
      { name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      { name: 'changed shifts', key: 'changes', render: val => val.length },
      { name: 'author', key: 'author', render: renderers.link('users', false, true) },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      {
        name: 'changed shifts',
        key: 'changes',
        render: renderers.array([
          { name: 'shift id', key: 'shiftId', render: renderers.link('shifts', false, true) },
          { name: 'revision id', key: 'revisionId' },
          { name: 'type', key: 'type' }
        ])
      },
      { name: 'author', key: 'author', render: renderers.link('users', false, true) },
      { name: 'created', key: 'created', render: renderers.dateFull }
    ]
  },
  plans: {
    title: 'Plans',
    model: 'plan',
    aliases: [
      'p',
      'pl'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink('plans', true) },
      { name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      { name: 'status', key: 'status' },
      { name: 'days', key: 'days', render: val => val ? val.length : nullDef },
      { name: 'shifts', key: 'days', render: renderers.planShifts },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      { name: 'status', key: 'status' },
      { name: 'days', key: '_days', render: (_, item) => item && item.days ? item.days.length : null },
      { name: 'shifts', key: '_shifts', render: renderers.planShifts },
      { name: 'created', key: 'created', render: renderers.dateFull }
    ],
    connectedKey: 'planId',
    connected: [
      {
        id: 'plannertasks',
        name: 'Planner tasks',
        col: 'plannertasks'
      }
    ]
  },
  offers: {
    title: 'Offers',
    model: 'offer',
    aliases: [
      'of',
      'off'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink('offers', true) },
      { name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      { name: 'shift id', key: 'shiftId', render: renderers.link('shifts', true, true) },
      { name: 'type', key: 'type' },
      { name: 'status', key: 'status' },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      { name: 'shift id', key: 'shiftId', render: renderers.link('shifts', false, true) },
      { name: 'action', key: 'action' },
      { name: 'type', key: 'type' },
      { name: 'status', key: 'status' },
      { name: 'stage', key: 'stage' },
      { name: 'author', key: 'authorId', render: renderers.link('users', false, true) },
      { name: 'created', key: 'created', render: renderers.dateFull }
    ],
    connectedKey: 'offerId',
    connected: [
      {
        id: 'actions',
        name: 'Actions',
        col: 'requiredactions',
        model: 'requiredaction',
        query: (id, item) => {
          const $in = []
          if (item.managerActionId) {
            $in.push(item.managerActionId)
          }
          if (item.announceActionId) {
            $in.push(item.announceActionId)
          }
          return { _id: { $in } }
        }
      },
    ]
  },
  feeditems: {
    title: 'Feed Items',
    model: 'feeditem',
    aliases: [
      'fi',
      'feed'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink('feeditems', true) },
      { name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      { name: 'subject', key: 'text', render: renderers.default },
      { name: 'pinned', key: 'pinned', render: renderers.boolean },
      { name: 'author', key: 'author', render: renderers.link('users', false, true) },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      {
        name: 'targets',
        key: 'targets',
        render: renderers.array([
          { name: 'user id', key: 'userId', render: renderers.link('users', false, true) },
          { name: 'require read?', key: 'readRequire', render: renderers.boolean },
          { name: 'read time', key: 'readOn', render: renderers.date },
          { name: 'created', key: 'created', render: renderers.date }
        ])
      },
      { name: 'subject', key: 'text', render: renderers.default },
      { name: 'text', key: 'text', render: renderers.default },
      { name: 'pinned', key: 'pinned', render: renderers.boolean },
      {
        name: 'reactions',
        key: 'reactions',
        render: renderers.array([
          { name: 'id', key: '_id', render: renderers.id },
          { name: 'user id', key: 'userId', render: renderers.link('users', false, true) },
          { name: 'text', key: 'text', render: renderers.default },
          { name: 'created', key: 'created', render: renderers.date }
        ])
      },
      {
        name: 'likes',
        key: 'likes',
        render: renderers.array([
          { name: 'user id', key: 'userId', render: renderers.link('users', false, true) },
          { name: 'created', key: 'created', render: renderers.date }
        ])
      },
      { name: 'author', key: 'author', render: renderers.link('users', false, true) },
      { name: 'created', key: 'created', render: renderers.dateFull }
    ]
  },
  timeoffs: {
    title: 'TimeOffs',
    model: 'timeoff',
    aliases: [
      'time',
      'off',
      'timeoff'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink('timeoffs', true) },
      { name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      { name: 'user id', key: 'userId', render: renderers.link('users', false, true) },
      { name: 'recurrence type', key: 'recurrence', render: val => val && val.type ? val.type : null },
      { name: 'recurrence value', key: 'recurrence', render: val => val && val.value ? val.value : null },
      { name: 'start', key: '_period_start', render: renderers.periodPart('start') },
      { name: 'end', key: '_period_end', render: renderers.periodPart('end') },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      { name: 'user id', key: 'userId', render: renderers.link('users', false, true) },
      { name: 'recurrence type', key: 'recurrence', render: val => val && val.type ? val.type : null },
      { name: 'recurrence value', key: 'recurrence', render: val => val && val.value ? val.value : null },
      { name: 'categoryId', key: 'categoryId', render: renderers.default },
	    {
		    name: 'category name',
		    key: 'workspaceId',
		    render: renderers.asyncList('workspaces', function (timeoff) {
			    return function (workspace) {
				    const cat = workspace.unavailabilityCategories.find(c => c._id === timeoff.categoryId);
				    return [cat.title]
			    }
		    })
	    },
      { name: 'note', key: 'note', render: renderers.default },
      { name: 'start', key: '_period_start', render: renderers.periodPart('start') },
      { name: 'end', key: '_period_end', render: renderers.periodPart('end') },
      { name: 'created', key: 'created', render: renderers.dateFull }
    ]
  },
  availabilities: {
    title: 'Availabilities',
    model: 'availability',
    aliases: [
      'av',
      'availability',
      'Availabilities'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink('availabilities', true) },
      { name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      { name: 'user id', key: 'userId', render: renderers.link('users', false, true) },
      { name: 'available', key: 'available', render: renderers.boolean },
      { name: 'recurrence type', key: 'recurrence', render: val => val && val.type ? val.type : null },
      { name: 'recurrence value', key: 'recurrence', render: val => val && val.value ? val.value : null },
      { name: 'start', key: '_period_start', render: renderers.periodPart('start') },
      { name: 'end', key: '_period_end', render: renderers.periodPart('end') },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'workspace id', key: 'workspaceId', render: renderers.link('workspaces', false, true) },
      { name: 'user id', key: 'userId', render: renderers.link('users', false, true) },
      { name: 'available', key: 'available', render: renderers.boolean },
      { name: 'recurrence type', key: 'recurrence', render: val => val && val.type ? val.type : null },
      { name: 'recurrence value', key: 'recurrence', render: val => val && val.value ? val.value : null },
      { name: 'note', key: 'note', render: renderers.default },
      { name: 'start', key: '_period_start', render: renderers.periodPart('start') },
      { name: 'end', key: '_period_end', render: renderers.periodPart('end') },
      { name: 'created', key: 'created', render: renderers.dateFull }
    ]
  },
  shorturls: {
    title: 'Short URLs',
    model: 'shorturl',
    aliases: [
      'su',
      'surl'
    ],
    keywords: [
      'code'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink('shorturls', true) },
      { name: 'code', key: 'code' },
      { name: 'target', key: 'target', render: val => m('a', { href: val }, 'link') },
      { name: 'created', key: 'created', render: renderers.date }
    ]
  },
  auditlogs: {
    title: 'Audit Logs',
    model: 'auditlog',
    aliases: [
      'log',
      'l',
      'al'
    ],
    keywords: [
      'type',
      'target',
      'name'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink('auditlogs', true) },
      { name: 'method', key: '_method', render: (_, item) => [item.type, item.name].join('.') },
      { name: 'target', key: 'target', render: (val, item, vnode) => renderers.link(item.type + 's', false, true)(val, item, vnode) },
      { name: 'user id', key: 'userId', render: renderers.link('users', false, true) },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'method', key: '_method', render: (_, item) => [item.type, item.name].join('.') },
      { name: 'target', key: 'target', render: (val, item, vnode) => renderers.link(item.type + 's', false, true)(val, item, vnode) },
      { name: 'user id', key: 'userId', render: renderers.link('users', false, true) },
      { name: 'created', key: 'created', render: renderers.dateFull }
    ]
  },
  plannertasks: {
    title: 'Planner Tasks',
    model: 'plannertask',
    keywords: [
      'planId',
      'data.plan.workspace.id'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.link('plannertasks') },
      { name: 'plan id', key: 'planId', render: renderers.link('plans', true) },
      { name: 'workspace', key: '_workspace', render: (_, item, vnode) => item.data && item.data.plan ? renderers.link('workspaces', false, true)(item.data.plan.workspace.id, item, vnode) : nullDef },
      { name: 'status', key: 'status' },
      { name: 'published', key: 'published', render: renderers.date },
      { name: 'created', key: 'created', render: renderers.date }
    ]
  },
  invalidtokens: {
    title: 'Invalidated Tokens',
    model: 'invalidtoken',
    keywors: [
      'it',
      'invt'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink('invalidtokens', true) },
      { name: 'type', key: 'type', render: renderers.default },
      { name: 'iss', key: 'token', render: renderers.jwt('iss') },
      { name: 'sub', key: 'token', render: renderers.jwt('sub', 'target') },
      { name: 'iat', key: 'token', render: renderers.jwt('iat', 'date') },
      { name: 'exp', key: 'token', render: renderers.jwt('exp', 'date') },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'type', key: 'type', render: renderers.default },
      { name: 'token', key: 'token', render: renderers.jwt() },
      { name: 'iss', key: 'token', render: renderers.jwt('iss') },
      { name: 'sub', key: 'token', render: renderers.jwt('sub', 'target') },
      { name: 'iat', key: 'token', render: renderers.jwt('iat', 'dateFull') },
      { name: 'exp', key: 'token', render: renderers.jwt('exp', 'dateFull') },
      { name: 'created', key: 'created', render: renderers.dateFull }
    ]
  },
  messages: {
    title: 'Sended Messages',
    model: 'message',
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink('messages', true) },
      { name: 'channel', key: 'channel', render: renderers.default },
      { name: 'to', key: 'options', render: val => val ? renderers.default(val.to) : null },
      { name: 'result', key: 'result', render: val => renderers.boolean(val) },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'channel', key: 'channel', render: renderers.default },
      { name: 'service', key: 'service', render: renderers.default },
      { name: 'to', key: 'options', render: val => val ? renderers.default(val.to) : null },
      { name: 'options', key: 'options', render: renderers.yamlDumpFull },
      { name: 'result', key: 'result', render: renderers.yamlDumpFull },
      { name: 'created', key: 'created', render: renderers.dateFull }
    ]
  },
  admins: {
    title: 'Admins',
    model: 'admin',
    aliases: [
      'adm'
    ],
    keywords: [
      'username'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.simpleLink('admins', true) },
      { name: 'username', key: 'username' },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'username', key: 'username' },
      { name: 'password', key: 'password', render: renderers.password },
      { name: 'created', key: 'created', render: renderers.dateFull }
    ],
    connected: [
      {
        id: 'adminlogs',
        name: 'Admin logs',
        col: 'adminlogs',
        model: 'adminlog',
        query: (id, item) => ({ user: item.username }),
        filterCols: c => c.key !== 'user'
      }
    ]
  },
  adminlogs: {
    title: 'Admin Logs',
    model: 'adminlog',
    aliases: [
      'adl',
      'adlog'
    ],
    keywords: [
      'user',
      'method',
      'data.query'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.link('adminlogs', true) },
      { name: 'user', key: 'user' },
      { name: 'method', key: 'method' },
      { name: 'data', key: 'data', render: renderers.yamlDump },
      { name: 'time', key: 'time', render: val => val ? `${Math.round(val * 100) / 100}ms` : nullDef },
      { name: 'created', key: 'created', render: renderers.date }
    ]
  },
  frontenddeploys: {
    title: 'Frontend Deploys',
    model: 'frontenddeploy',
    sort: {
      time: -1
    },
    aliases: [
      'fd'
    ],
    keywords: [
      'type'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.link('frontenddeploys', true) },
      { name: 'type', key: 'type' },
      { name: 'commit', key: 'commit' },
      { name: 'version', key: 'version' },
      { name: 'time', key: 'time', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'type', key: 'type' },
      { name: 'commit', key: 'commit' },
      { name: 'version', key: 'version' },
      { name: 'time', key: 'time', render: renderers.dateFull }
    ]
  },
  invites: {
    title: 'Invites',
    model: 'invite',
    sort: {
      created: -1
    },
    aliases: [
      'i',
      'inv'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.link('invites', true) },
      { name: 'email', key: 'email' },
      { name: 'workspaceId', key: 'workspaceId', render: renderers.link('workspaces', true) },
      { name: 'roleType', key: 'roleType' },
      { name: 'resolved', key: 'resolved', render: renderers.boolean },
      { name: 'cancelled', key: 'cancelled', render: renderers.boolean },
      { name: 'expiration', key: 'expiration', render: (_) => _ ? renderers.dateFull(_) : null },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.id },
      { name: 'email', key: 'email' },
      { name: 'workspaceId', key: 'workspaceId', render: renderers.link('workspaces', true) },
      { name: 'userExisted', key: 'userExisted' },
      { name: 'userData', key: 'userData', render: _ => JSON.stringify(_) },
      { name: 'mergeWith', key: 'mergeWith', render: renderers.link('users', true) },
      { name: 'roleType', key: 'roleType' },
      { name: 'roleData', key: 'roleData' },
      { name: 'roleId', key: 'roleId' },
      { name: 'token', key: 'token' },
      { name: 'author', key: 'author', render: renderers.link('users', true) },
      { name: 'resolved', key: 'resolved', render: renderers.boolean },
      { name: 'cancelled', key: 'cancelled', render: renderers.boolean },
      { name: 'expiration', key: 'expiration', render: (_) => _ ? renderers.dateFull(_) : null },
      { name: 'created', key: 'created', render: renderers.date }
    ]
  },
  requiredactions: {
    title: 'Required Actions',
    model: 'requiredaction',
    sort: {
      created: -1
    },
    aliases: [
      'ac'
    ],
    keywords: [
      'action'
    ],
    cols: [
      { name: 'id', key: '_id', render: renderers.link('requiredactions', true) },
      { name: 'type', key: 'type' },
      { name: 'deadline', key: 'deadline', render: renderers.dateFull },
      { name: 'resolved', key: 'resolved', render: renderers.dateFull },
      { name: 'created', key: 'created', render: renderers.date }
    ],
    detail: [
      { name: 'id', key: '_id', render: renderers.link('requiredactions', true) },
      { name: 'type', key: 'type' },
      { name: 'deadline', key: 'deadline', render: renderers.dateFull },
      { name: 'resolved', key: 'resolved', render: renderers.dateFull },
      { name: 'created', key: 'created', render: renderers.date }
    ]
  },
	usages: {
		title: 'Usages',
		model: 'usage',
		sort: {
			created: -1
		},
		aliases: [],
		keywords: [
			'usage'
		],
		cols: [
			{ name: 'id', key: '_id', render: renderers.link('usages', false) },
			{ name: 'type', key: 'type', render: renderers.default },
			{ name: 'value', key: 'value', render: renderers.default },
			{ name: 'organization id', key: 'organizationId', render: renderers.link('organizations', false, true) },
			{ name: 'created', key: 'created', render: renderers.date }
		],
		detail: [
			{ name: 'id', key: '_id', render: renderers.link('usages', false) },
			{ name: 'type', key: 'type', render: renderers.default },
			{ name: 'value', key: 'value', render: renderers.default },
			{ name: 'organization id', key: 'organizationId', render: renderers.link('organizations', false, true) },
			{ name: 'created', key: 'created', render: renderers.date }
		]
	},
}
