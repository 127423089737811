/* global localStorage */

const m = require('mithril')
const uson = require('uson')

const ds = require('./lib/ds')
const store = require('./lib/store')
const data = require('./lib/data')

const cols = require('./schema/collections')
const envs = require('./schema/envs')
const specConfig = require('./schema/spec')

let loading = true

function findEnv (hostname) {
  for (const e of envs) {
    if (typeof e.host === 'string' && e.host === hostname) {
      e.href = e.host
      return e
    }
    if (typeof e.host === 'object' && e.host instanceof RegExp) {
      const match = hostname.match(e.host)
      if (match) {
        if (match[1]) {
          e.api = e.api.replace('%sub%', match[1])
          e.apiPublic = e.apiPublic.replace('%sub%', match[1])
          e.fe = e.fe.map(i => i.replace('%sub%', match[1]))
          e.href = e.href.replace('%sub%', match[1])
        }
        return e
      }
    }
  }
  return null
}

const state = {
  env: findEnv(window.location.hostname),
  cols
}
// console.log('Env:', state.env)

if (!state.env) {
  throw new Error(`env not found: ${window.location.hostname}`)
}

const ALIASES = [
  { keys: ['st', 'status'], target: '/status' },
  { keys: ['db', 'data', 'database'], target: '/db' },
  { keys: ['eval', 'engine'], target: '/engine', filterTarget: x => `/engine/eval/${encodeURIComponent(x)}` },
  { keys: ['cache'], target: '/cache' },
  { keys: ['t', 'tools', 'uson'], target: '/tools' },
  { keys: ['locales', 'loc'], target: '/spec/locales' },
  { keys: ['tz', 'timezones'], target: '/spec/timezones' },
  { keys: ['rules'], target: '/spec/rules' },
  { keys: ['schema', 'graphql', 'gql'], target: '/spec/graphql' },
  { keys: ['vars', 'variables'], target: '/spec/variables' },
  { keys: ['contract', 'contracts'], target: '/spec/contractTypes' },
  { keys: ['msg', 'message', 'messages'], target: '/spec/messages' },
  { keys: ['ntf', 'notify', 'notification', 'notifications'], target: '/spec/notifications' },
  { keys: ['conf', 'cnf', 'config'], target: '/config' },
  { keys: ['gh', 'github'], target: 'https://github.com/Dayswaps/backend-node' },
  { keys: ['fe', 'frontend'], target: (_, state) => state.config.core.frontend.url },
  { keys: ['ur', 'uptimerobot'], target: 'https://status.dayswaps.top/' },
  { keys: ['ci', 'circleci'], target: 'https://circleci.com/gh/Dayswaps' },
  { keys: ['api'], target: (vnode) => vnode.attrs.env.apiPublic },
  { keys: ['aapi', 'adminapi'], target: vnode => vnode.attrs.env.api }
  // TODO { keys: [ 'logout' ], target: '/logout' },
]

document.title = `${state.env.name} [ds-admin]`

function typeSearch (e) {
  q = e.target.value
}

function doLogout (vnode) {
  return function () {
    store.set(vnode, { auth: false })
    m.route.set('/login')
  }
}
function findCol (code) {
  return Object.keys(cols).find(c => {
    const cd = cols[c]
    if (c === code || (cd.aliases && cd.aliases.indexOf(code) >= 0) || cd.model === code) {
      return true
    }
  })
}
function findAlias (code) {
  return ALIASES.find(a => a.keys.indexOf(code) >= 0)
}

function doSearch (vnode) {
  return function () {
    console.log('Expression: ', q)
    if (q) {
      let target = null
      let parsed = null
      try {
        parsed = uson.parse(q)
      } catch (e) {
        return false
      }
      const cmd = parsed[0]
      if (!cmd) {
        return false
      }
      switch (typeof cmd) {
        case 'string': {
          // keyword
          const col = findCol(cmd)
          if (col) {
            target = `/db/${col}`
          }
          if (!target) {
            const alias = findAlias(cmd)
            if (alias) {
              target = typeof alias.target === 'function' ? alias.target(vnode, state) : alias.target
            }
          }
          break
        }
        case 'object': {
          // filter => object
          const k = Object.keys(cmd)[0]
          let filter = cmd[k]
          if (typeof filter !== 'string') {
            filter = JSON.stringify(filter)
          }
          const col = findCol(k)
          if (col) {
            target = `/db/${col}/filter/${encodeURIComponent(filter)}`
          }
          if (!target) {
            const alias = findAlias(k)
            if (alias && alias.filterTarget) {
              target = alias.filterTarget(filter)
            }
          }
          break
        }
      }

      if (!target) {
        return false
      }
      q = ''
      m.redraw()
      console.log('Redirecting:', target)
      if (target.match(/https?:\/\//)) {
        window.open(target)
      } else {
        m.route.set(target)
      }
    }
    return false
  }
}

let q = ''

const Layout = {
  oninit (vnode) {
    ds.url = vnode.attrs.env.api
    q = ''

    const remoteStore = localStorage.getItem('store')
    if (remoteStore) {
      store.init(vnode, JSON.parse(remoteStore))
      console.log(`store: ${JSON.stringify(store.get())}`)
      const auth = store.get('auth')
      if (auth && auth.token) {
        ds.token = auth.token
        if (auth && m.route.get() === '/login') {
          m.route.set('/status')
        }
      }
    }

    data.meta()
      .then(res => {
        console.log(`BE version: ${res.data.meta.version}`)
        state.apiMeta = res.data.meta
        data.config()
          .then(res => {
            state.config = res.data.config
            loading = false
            if (res.errors &&
                res.errors[0] &&
                res.errors[0].extension &&
                res.errors[0].extension.code === 'admin.permission_denied') {
              store.set(vnode, null)
              m.route.set('/login')
            }
          })
      })
      .catch(e => {
        console.log(e)
        if (e.code === 400 &&
            e.response &&
            e.response.errors &&
            e.response.errors[0] &&
            e.response.errors[0].extensions.code === 'auth.invalid_token') {
          store.set(vnode, null)
          m.route.set('/login')
          m.redraw()
        }
      })
  },
  view (vnode) {
    const auth = store.get('auth')
    const env = vnode.attrs.env
    const labeled = (val, cls, isRight) => {
      const ico = cls ? m('i', { class: `${cls} ds-menulink-ico ${isRight ? 'ds-right' : ''} ${!val ? 'ds-empty' : ''}` }) : null
      const v = m('span.ds-val', val)
      return m('.ds-menulink', ico ? (isRight ? [v, ico] : [ico, v]) : v)
    }

    return loading ? '' : m('div', [
      m('.ds-header', [
        !auth ? null : m('nav#navbar.navbar.is-dark', { style: `background-color: ${env.color}` }, [
          m('.container', [
            m('.navbar-brand', [
              m('a.navbar-item.has-dropdown.is-hoverable', [
                m(m.route.Link, { class: 'navbar-link is-arrowless', href: '/' }, [
                  m('b', env.name),
                  state.apiMeta.version ? m('.tag', { style: 'margin-left: 8px;' }, state.apiMeta.version) : null
                ]),
                m('.navbar-dropdown', envs.map(e => {
                  return m('a.dropdown-item', { href: e.href, style: `background-color: ${e.color}; color: white;` }, e.name)
                }))
              ])
            ]),
            m('.navbar-menu', [
              auth ? m('.navbar-start', [
                m('a.navbar-item', { href: env.fe[0], target: '_blank' }, labeled('FE', 'fas fa-external-link-square-alt')),
                m('form.navbar-item', { onsubmit: doSearch(vnode), style: 'margin: 0;' }, [
                  m('input.input', { oninput: typeSearch, value: q, style: 'width: 300px', placeholder: 'Expression ..' })
                ]),
                m(m.route.Link, { href: '/status', class: 'navbar-item' }, labeled(m('span', 'Status'), 'far fa-check-circle')),
                m('a.navbar-item.has-dropdown.is-hoverable', [
                  m(m.route.Link, { href: '/db', class: 'navbar-link is-arrowless' }, labeled('DB', 'fas fa-database')),
                  m('.navbar-dropdown', Object.keys(cols).map(cid => {
                    const c = cols[cid]
                    return m(m.route.Link, { class: 'navbar-item', href: `/db/${cid}` }, c.title)
                  }))
                ]),
                m(m.route.Link, { href: '/snippets', class: 'navbar-item' }, labeled(m('span', 'Snippets'), 'fas fa-play')),
                m('a.navbar-item.has-dropdown.is-hoverable', [
                  m('.navbar-link.is-arrowless', labeled('Spec', 'fas fa-hashtag')),
                  m('.navbar-dropdown', Object.keys(specConfig).map(sk => {
                    const s = specConfig[sk]
                    return m(m.route.Link, { class: 'navbar-item', href: `/spec/${sk}` }, s.name)
                  }))
                ]),
                m('a.navbar-item.has-dropdown.is-hoverable', [
                  m('.navbar-link.is-arrowless', labeled('Runtime', 'fas fa-cog')),
                  m('.navbar-dropdown', [
                    m(m.route.Link, { href: '/engine', class: 'navbar-item' }, 'Engine'),
                    m(m.route.Link, { href: '/cache', class: 'navbar-item' }, 'Cache'),
                    m(m.route.Link, { href: '/config', class: 'navbar-item' }, 'Config'),
                    m('hr.navbar-divider'),
                    m(m.route.Link, { href: '/tools', class: 'navbar-item' }, 'Tools')
                  ])
                ])
              ]) : null,
              m('.navbar-end', (function () {
                if (auth) {
                  return [
                    m('a.navbar-item.has-dropdown.is-hoverable', [
                      m('.navbar-link.is-arrowless', labeled('External', 'fas fa-external-link-alt')),
                      m('.navbar-dropdown.is-right', [
                        m('a.navbar-item', { href: 'https://status.dayswaps.top/', target: '_blank' }, 'UptimeRobot'),
                        m('a.navbar-item', { href: 'https://github.com/Dayswaps/backend-node', target: '_blank' }, labeled('GitHub', 'fab fa-github'))
                      ])
                    ]),
                    m('a.navbar-item.has-dropdown.is-hoverable', [
                      m('.navbar-link.is-arrowless', m('b', `@${auth.username}`)),
                      m('.navbar-dropdown.is-right', [
                        m('a.navbar-item', { onclick: doLogout(vnode) }, m('div', [m('i', { class: 'fas fa-sign-out-alt' }), ' Logout']))
                      ])
                    ])
                  ]
                }
                return m(m.route.Link, { href: '/login', class: 'navbar-item' }, 'Login')
              }()))
            ])
          ])
        ])
      ]),
      m('.container', [
        m('#page', vnode.children)
      ])
    ])
  }
}

const root = document.getElementById('ds-console')

const components = {
  status: require('./components/status'),
  login: require('./components/login'),
  cache: require('./components/cache'),
  db: require('./components/db'),
  collection: require('./components/collection'),
  item: require('./components/item'),
  edit: require('./components/edit'),
  config: require('./components/config'),
  spec: require('./components/spec'),
  engine: require('./components/engine'),
  control: require('./components/control'),
  tools: require('./components/tools'),
  snippets: require('./components/snippets'),
  snippet: require('./components/snippet'),
  snippetdebug: require('./components/snippetdebug')
}

function component (name) {
  const cstate = (vnode) => {
    return Object.assign(vnode.attrs, state)
  }
  return {
    render: (vnode) => {
      return m(Layout, cstate(vnode), loading ? null : m(components[name], cstate(vnode)))
    }
  }
}

m.route(root, '/login', {
  '/status': component('status'),
  '/login': component('login'),
  '/cache': component('cache'),
  '/config': component('config'),
  '/db': component('db'),
  '/db/:col': component('collection'),
  '/db/:col/filter/:q': component('collection'),
  '/db/:col/:id': component('item'),
  '/db/:col/:id/edit': component('edit'),
  '/spec/:type': component('spec'),
  '/spec/:type/:query': component('spec'),
  '/engine': component('engine'),
  '/engine/eval/:query': component('engine'),
  '/snippets': component('snippets'),
  '/snippet-debug': component('snippetdebug'),
  '/snippet-debug/:id': component('snippetdebug'),
  '/snippet/:id': component('snippet'),
  '/control': component('control'),
  '/tools': component('tools')
})
