
const m = require('mithril')
const control = require('../lib/control')
const runComponent = require('./snippetrun')
const snippetsLib = require('../lib/snippets')

const Colored = require('./colored')

let snippet = null
let showSource = false

function deleteSnippet (id) {
  return function () {
    if (confirm(`Really delete snippet "${id}"?`)) {
      control.objectDelete({ model: 'snippet', id: id.split('@')[0] }).then(() => {
        m.route.set('/snippets')
      })
    }
  }
}

module.exports = {

  oninit (vnode) {
    snippet = null
    snippetsLib.load().then(arr => {
      snippet = arr.find(s => s.id === vnode.attrs.id)
    })
  },

  view () {
    if (!snippet) {
      return m('div', 'Loading ..')
    }
    return m('div', [
      m('.level', [
        m('.level-left', [
          m('.level-item', [
            m('.title.is-3', [
              m(m.route.Link, { href: '/snippets' }, [m('i', { class: 'fas fa-play' }), 'Snippets']),
              ' → ',
              m('span', `${snippet.id}`)
            ])
          ])
        ]),
        m('.level-right', [
          m('.level-item', [
            m(m.route.Link, { href: `/snippet-debug/${snippet.id}` }, m('button.button', 'Edit'))
          ])
        ])
      ]),
      m('table.table.is-fullwidth.ds-detail-table', [
        m('tr', [
          m('th', 'ID'),
          m('td', [
            snippet.id + ' ',
            snippet.local ? [' - ', m('a', { onclick: deleteSnippet(snippet.id) }, 'Delete snippet')] : ''
          ])
        ]),
        m('tr', [
          m('th', 'Name'),
          m('td', m('strong', snippet.name))
        ]),
        m('tr', [
          m('th', 'Minimum BE version'),
          m('td', snippet.minVersion)
        ]),
        m('tr', [
          m('th', 'Source code'),
          m('td',
            showSource
              ? [
                m(Colored, { text: snippet.source, type: 'javascript' }),
                m('a', { onclick: () => showSource = false }, 'Hide source')
              ]
              : m('a', { onclick: () => showSource = true }, 'Show source')
          )
        ])
      ]),
      m('div', { style: 'margin-bottom: 10em' }, [
        m('.title.is-4', 'Run'),
        m(runComponent, { source: snippet.source, id: snippet.name })
      ])
    ])
  }
}
