
const m = require('mithril')
const master = require('./master')
const yaml = require('js-yaml')
const data = require('./data')

module.exports = {

  load () {
    return master.snippets().then(arr => {
      return data.dataQuery({ model: 'snippet', query: {} }).then(res => {
        return arr.concat(res.data.dataQuery.map(i => {
          const dec = i.dec || {}
          return Object.assign({
            id: i._id + '@env',
            local: true,
            source: i.source
          }, dec)
        }))
      })
    })
  },

  process (source) {
    let s
    try {
      s = eval(source)
    } catch (e) {
      return m('.notification.is-danger', `Source error: ${e.message}`)
    }
    if (typeof (s.schema) === 'string') {
      try {
        s.schema = yaml.safeLoad(s.schema)
      } catch (e) {
        return m('.notification.is-danger', `Schema YAML error: ${e}`)
      }
    }
    return s
  }
}
