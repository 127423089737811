const m = require('mithril')
const cols = require('../schema/collections')
const data = require('../lib/data')

let pageStore = {}

function loadPageStore (vnode) {
  pageStore = {}
  m.redraw()
  const col = cols[vnode.attrs.col]
  const query = {
    _id: vnode.attrs.id
  }
  data.dataQuery({ model: col.model, query }).then(res => {
    pageStore.item = res.data.dataQuery[0]
    m.redraw()
  })
}

module.exports = {

  oninit (vnode) {
    loadPageStore(vnode)
  },
  onupdate (vnode) {
    if (pageStore.item && pageStore.item._id && vnode.attrs.id !== pageStore.item._id) {
      pageStore = {}
      loadPageStore(vnode)
    }
  },
  onremove () {
    pageStore = {}
  },
  view (vnode) {
    const col = cols[vnode.attrs.col]
    const edit = col.edit
    const keys = Object.keys(edit.schema.properties)
    return pageStore.item ? m('div', [
      m('.level', [
        m('.level-left', [
          m('.level-item', [
            m('.title.is-3', [
              m(m.route.Link, { href: '/db' }, 'Database'),
              ' → ',
              m(m.route.Link, { href: `/db/${vnode.attrs.col}` }, col.title),
              ' → ',
              pageStore.item._id
            ])
          ])
        ]),
        m('.level-right', [
          m('.level-item', [
            m(m.route.Link, { href: `/db/${vnode.attrs.col}/${vnode.attrs.id}` }, m('button.button', 'Back to view'))
          ])
        ])
      ]),
      edit && pageStore.item ? m('div', [
        m('form.form', m('div', [
          m('div', keys.map(k => {
            return m('.field', [
              m('label.label', k),
              m('.control', m('input.input', { type: 'text', value: pageStore.item[k] }))
            ])
          })),
          m('.level', { style: 'margin-top: 2em;' }, [
            m('.level-left', [
              m('.level-item', [
                m('button.button.is-primary', 'Save')
              ])
            ])
          ])
        ]))
      ]) : 'Editing not available'
    ]) : m('div', 'Loading ..')
  }
}
