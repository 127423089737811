
const m = require('mithril')
// const specConfig = require('../schema/spec')
const data = require('../lib/data')

let current = null
let pageStore = {}

function loadPageStore (vnode) {
  pageStore = {}
  current = vnode.attrs.type
  data.dataQuery({ model: 'snippet' }).then(res => {
    pageStore.snippets = res.data.dataQuery
    console.log(pageStore)
    m.redraw()
  })
}

module.exports = {
  oninit (vnode) {
    loadPageStore(vnode)
  },
  onupdate (vnode) {
    if (vnode.attrs.type !== current) {
      loadPageStore(vnode)
    }
  },
  onremove () {
    pageStore = {}
    current = null
  },
  view (vnode) {
    // const spec = specConfig[vnode.attrs.type]
    return m('div', { style: 'margin-bottom: 2em;' }, [
      m('.title.is-3', 'Actions'),
      pageStore.snippets ? m('div', pageStore.snippets.map(sn => {
        return m('.box', [m('b', sn.code), ' - ', sn.title])
      })) : ''
      // pageStore.spec ? m('pre.code', typeof pageStore.spec === 'string' ? pageStore.spec : yaml.safeDump(pageStore.spec)) : m('div', 'Loading spec ..')
    ])
  }
}
