const m = require('mithril')
const yaml = require('js-yaml')
const data = require('../lib/data')
const cols = require('../schema/collections')
const table = require('../lib/table')
const control = require('../lib/control')
const Colored = require('./colored')

const MAX_SUB_ITEMS = 10

let pageStore = {}

const EditableValue = {
  oninit (vnode) {
    const isObjEdit = vnode.attrs.column.editKey && vnode.attrs.edit
    this._input = vnode.attrs.editItem[vnode.attrs.column.key]
    let objEditItem = null
    let  multipleKeys = null
    if(isObjEdit) {
      multipleKeys = vnode.attrs.column.editKey.split('.')
      if (multipleKeys.length > 1) {
        let val = null
        let schema = null
        multipleKeys.forEach((m, i) => {
          if (val === null && schema === null) {
            val = vnode.attrs.editItem[m]
          } else {
            val = val ? val[m] : null
          }
        })
        this._input = val
        objEditItem = val
        if(Array.isArray(val)){
          this._input = val.join(",")
          objEditItem = val.join(",")
        }
      }
    }
    this._error = null
    this.edited = false
    this.changeInput = (e) => {
      this._input = e.target.value
    }
    this.cancelEdit = () => {
      this._input = vnode.attrs.editItem[vnode.attrs.column.key]
      this._error = null
      this.edited = false
    }
    this.startEdit = () => {
      this.edited = true
    }
    this.submitForm = () => {
      const model = vnode.attrs.colSpec.model
      const item = vnode.attrs.editItem
      let column = vnode.attrs.column
      if(isObjEdit){
        if (this._input === objEditItem) {
          this.edited = false
          return false
        }
        control.objectUpdateWithMultipleKeys({
          model,
          id: item._id,
          data: this._input,
          keys: multipleKeys
        }).then((res) => {
          if (res.errors) {
            const e = res.errors[0]
            this._error = `Error: ${e.message}`
          }
          if (res.data.objectUpdateWithMultipleKeys) {
            this.edited = false
            vnode.attrs.updateDetail()
          }
        })
      }else{
        if (this._input === vnode.attrs.editItem[vnode.attrs.column.key]) {
          this.edited = false
          return false
        }
        control.objectUpdateSimple({
          model,
          id: item._id,
          data: {
            [column.key]: this._input
          }
        }).then((res) => {
          if (res.errors) {
            const e = res.errors[0]
            this._error = `Error: ${e.message}`
            // + (e.extensions && e.extensions.exception ? `\n\nStacktrace:\n  ${e.extensions.exception.stacktrace}` : '')
          }
          if (res.data.objectUpdateSimple) {
            this.edited = false
            vnode.attrs.updateDetail()
          }
        })
      }
      return false
    }
  },
  view (vnode) {
    let columnSchema = vnode.attrs.edit
      ? vnode.attrs.edit.schema.properties[vnode.attrs.column.key]
      : null
    let isChanged = (this._input !== vnode.attrs.editItem[vnode.attrs.column.key])

    if(vnode.attrs.column.editKey && vnode.attrs.edit) {
      const multipleKeys = vnode.attrs.column.editKey.split('.')
      if (multipleKeys.length > 1) {
        let val = null
        let schema = null
        multipleKeys.forEach((m, i) => {
          if (val === null && schema === null) {
            val = vnode.attrs.editItem[m]
            schema = vnode.attrs.edit.schema.properties[m] ? vnode.attrs.edit.schema.properties[m] : null
          } else {
            val = val ? val[m] : null
            schema = schema[m] ? schema[m] : null
          }
        })
        columnSchema = schema
        isChanged = (this._input !== val)
      }
    }
    return m('div', [
      m('.ds-editable-value', [
        this.edited
          ? [
            m('form', { onsubmit: this.submitForm }, [
              this._error ? m('pre.notification.is-danger', this._error) : '',
              m('input.input', { oninput: this.changeInput, value: this._input }),
              m('.level', { style: 'margin-top: 0.5em;' }, [
                m('.level-left', [
                  m('.level-item', [
                    m('button.button.is-primary', { type: 'submit', disabled: !isChanged }, 'Save')
                  ]),
                  m('.level-item', [
                    m('button.button', { type: 'button', onclick: this.cancelEdit }, 'Cancel')
                  ])
                ])
              ])
            ])
          ]
          : [
            m('span', vnode.attrs.value),
            ' ',
            columnSchema ? m('a.ds-edit-link', { onclick: this.startEdit }, '(edit)') : ''
          ]
      ])
    ])
  }
}

function refresh (vnode) {
  return () => {
    loadPageStore(vnode)
  }
}

function loadPageStore (vnode) {
  pageStore = {}
  m.redraw()
  const col = cols[vnode.attrs.col]
  const query = {
    _id: vnode.attrs.id
  }
  data.dataQuery({ model: col.model, query }).then(res => {
    if (res.data.dataQuery.length === 0) {
      m.route.set(`/db/${vnode.attrs.col}`)
    }
    pageStore.item = res.data.dataQuery[0]
    m.redraw()

    // load connected objects
    if (col.connected) {
      return Promise.all(col.connected.map(co => {
        const defaultFind = (id, d) => {
          if (!co.query && col.connectedKey) {
            co.query = id => ({ [col.connectedKey]: id })
          }
          const model = co.col ? cols[co.col].model : co.model
          return d.dataQuery({ model, query: co.query(id, pageStore.item), limit: MAX_SUB_ITEMS, sort: co.sort }, true).then(res => {
            return [res.data.dataQueryCount, res.data.dataQuery]
          })
        }
        const find = co.find || defaultFind
        return find(vnode.attrs.id, data).then(([count, res]) => {
          pageStore[co.id] = res
          pageStore[co.id + '_count'] = count
        })
      })).then(() => {
        m.redraw()
      })
    }
  })
}
module.exports = {
  oninit (vnode) {
    loadPageStore(vnode)
  },
  onupdate (vnode) {
    if (pageStore.item && pageStore.item._id && vnode.attrs.id !== pageStore.item._id) {
      pageStore = {}
      loadPageStore(vnode)
    }
  },
  onremove () {
    pageStore = {}
  },
  view (vnode) {
    const col = cols[vnode.attrs.col]
    return pageStore.item ? m('div', [
      m('.level', [
        m('.level-left', [
          m('.level-item', [
            m('.title.is-3', [
              m(m.route.Link, { href: '/db' }, [m('i', { class: 'fas fa-database' }), 'Database']),
              ' → ',
              m(m.route.Link, { href: `/db/${vnode.attrs.col}` }, col.title),
              ' → ',
              pageStore.item._id
            ])
          ])
        ]),
        m('.level-right', [
          /* col.edit ? m('.level-item', [
            m(m.route.Link, { href: `/db/${vnode.attrs.col}/${vnode.attrs.id}/edit` }, m('button.button.is-info', 'Edit'))
          ]) : '', */
          m('.level-item', [
            m('button.button', { onclick: refresh(vnode) }, 'Refresh')
          ])
        ])
      ]),
      pageStore.item && col.tools ? m('div', { style: 'margin-bottom: 2em;' }, [
        m('.title.is-4', 'Tools'),
        m('div', Object.keys(col.tools).map(tool => {
          const t = col.tools[tool]
          return m('div', [
            m('.title.is-5.mt-4', t.title || tool),
            m(t.component, { item: pageStore.item, env: vnode.attrs.env, config: vnode.attrs.config })
          ])
        }))
      ]) : '',
      col.detail ? m('table.table', { style: 'width: 100%;' }, col.detail.map(c => {
        let val = pageStore.item[c.key]
	      if ([null, undefined].includes(val)) {
					return null
				}
        let arrayLength = null
        if (Array.isArray(val)) {
          if (typeof c.filter === 'function') {
            val = val.filter(c.filter)
          }
          arrayLength = val.length
        }
        const isArray = Array.isArray(val)
        val = table.compileColVal(c, pageStore.item, vnode, val)
        return m('tr.ds-item-detail-row', [
          m('th', { style: 'width: 20%; text-align: right;' }, c.name + (arrayLength !== null ? ` (${arrayLength})` : '')),
          m('td.ds-item-detail',isArray ? val :  m(EditableValue, {
            column: c,
            value: val,
            edit: col.edit,
            editItem: pageStore.item,
            colSpec: col,
            updateDetail: () => {
              return refresh(vnode)()
            }
          }))
        ])
      })) : '',
      col.connected ? m('div', col.connected.map(co => {
        const data = pageStore[co.id]
        const count = pageStore[co.id + '_count']
        let ccols = co.cols
        if (co.col) {
          ccols = cols[co.col].cols
        }
        if (!co.filterCols && col.connectedKey) {
          co.filterCols = c => c.key !== col.connectedKey
        }
        if (co.filterCols) {
          ccols = ccols.filter(co.filterCols)
        }
        let link = null
        if (!co.link && co.query) {
          const lq = co.query(pageStore.item._id, pageStore.item)
          link = Object.keys(lq).map(l => `${l}:${lq[l]}`).join(' ')
        } else if (!co.link) {
          link = `${col.connectedKey}:${pageStore.item._id}`
        } else {
          link = co.link(pageStore.item._id, pageStore.item, co, col)
        }
        const dbLink = `/db/${co.col}/filter/${encodeURIComponent(link)}`
        return m('div', { style: 'margin-bottom: 2em;' }, [
          m('.title.is-5', [
            co.col ? m(m.route.Link, { href: dbLink }, co.name) : co.name,
            (typeof count === 'number' ? ` (${count})` : '')
          ]),
          m('div', [
            data ? m('.container', [
              data.length > 0 ? m('div', [
                m('table.table.is-fullwidth', [
                  m('thead', m('tr', ccols.map(col => m('th', col.name)))),
                  m('tbody', data.map(di => {
                    return m('tr', ccols.map(col => {
                      return m('td', table.compileColVal(col, di, vnode))
                    }))
                  })),
                  count && count > MAX_SUB_ITEMS ? m('div', { style: 'margin-top: 10px;' }, [
                    m(m.route.Link, { href: dbLink }, `Show all ${count} items`)
                  ]) : ''
                ])
              ]) : m('div', 'No items found\n\n')
            ]) : m('div', 'Loading data ..')
          ])
        ])
      })) : '',
      m('div', { style: 'margin-top: 5em;' }, [
        m('.title.is-4', 'Raw object'),
        m(Colored, { text: yaml.safeDump(pageStore.item) })
      ])
    ]) : m('div', 'Loading ..')
  }
}
