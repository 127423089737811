const m = require('mithril')
const yaml = require('js-yaml')
const specConfig = require('../schema/spec')
const data = require('../lib/data')
const Colored = require('./colored')

let current = null
let pageStore = {}

function loadPageStore (vnode) {
  pageStore = {}
  current = vnode.attrs.type
  data.spec({ type: vnode.attrs.type }).then(res => {
    pageStore.spec = res.data.spec
    m.redraw()
  })
  data.spec({ type: 'locales' }).then(res => {
    pageStore.locales = res.data.spec
    m.redraw()
  })
}

function msgLocales (key, lang = 'cs') {
  if (!pageStore.locales) {
    return null
  }
  const [p1, p2] = key.split('.')
  const loc = pageStore.locales.locales[lang]
  if (!loc || !loc.messages || !loc.messages[p1] || !loc.messages[p1][p2]) {
    return null
  }
  return loc.messages[p1][p2]
}

function messagePreviewLoad ({ item }, language = 'cs') {
  if (item) {
    item.preview = null
    data.messagePreview({ type: item.type, language }).then(res => {
      if (res.errors) {
        item.error = res.errors[0]
      } else {
        item.preview = res.data.messagePreview
        item.error = null
      }
      m.redraw()
    })
  }
}

function changeLanguage (e) {
  this.language = e.currentTarget.value
  messagePreviewLoad(this.messages, this.language)
  m.redraw()
}

module.exports = {
  oninit (vnode) {
    loadPageStore(vnode)

    this.language = 'cs'

    if (vnode.attrs.type === 'notifications') {
      this.notifications = {
        item: null,
        click: type => {
          return () => {
            this.notifications.item = { type }
            m.route.set(`/spec/notifications/${type}`)
            messagePreviewLoad(this.notifications, this.language)
          }
        },
        reset: () => {
          this.notifications.item = null
          m.route.set('/spec/notifications')
        }
      }
      if (vnode.attrs.query) {
        this.notifications.item = { type: vnode.attrs.query }
      }
      messagePreviewLoad(this.notifications, this.language)
    }
  },
  onupdate (vnode) {
    if (vnode.attrs.type !== current) {
      loadPageStore(vnode)
    }
    /* if (vnode.attrs.type === 'messages') {
      messagePreviewLoad(this.messages)
    } */
  },
  onremove () {
    pageStore = {}
    current = null
  },
  view (vnode) {
    const specName = vnode.attrs.type
    const spec = specConfig[specName]
    if (!spec || !pageStore.spec) {
      return 'Loading ..'
    }
    let content = null
    if (specName === 'notifications') {
      content = m('div', [
        m('.level', [
          m('.level-left'),
          m('.level-right', [
            m('.level-item', 'Language:'),
            m('.level-item.select', [
              m('select', { value: this.language, onchange: changeLanguage.bind(this) }, [
                m('option', { value: 'cs' }, 'cs'),
                m('option', { value: 'en' }, 'en')
              ])
            ])
          ])
        ]),
        m('.columns', { style: 'margin-top: 2em;' }, [
          m('.column.is-one-third', Object.keys(pageStore.spec).map(catId => {
            const cat = pageStore.spec[catId]
            return m('div', { style: 'margin-bottom: 2em;' }, [
              m('.title.is-5', catId),
              m('table.table', { width: '100%', style: 'margin-bottom: 1em;' }, Object.keys(cat).map(msg => {
                return m('tr', m('td', m('a', { onclick: this.notifications.click([catId, msg].join('.')) }, msg)))
              }))
            ])
          })),
          m('.column.is-two-thirds', [
            m('div', this.notifications.item
              ? (() => {
                const item = this.notifications.item
                const type = item.type
                const preview = item.preview
                const [catId, msgId] = type.split('.')
                if (!pageStore.spec[catId] || !pageStore.spec[catId][msgId]) {
                  this.notifications.reset()
                }
                const msg = pageStore.spec[catId][msgId]
                return m('div', [
                  m('.level', [
                    m('.level-left', [
                      m('.level-item', [
                        m('.title.is-4', type)
                      ])
                    ])
                  ]),
                  m('.title.is-5', { style: 'margin-top: 2em;' }, 'Email'),
                  msg.channels.email ? [
                    preview && preview.rendered.email
                      ? m('div', [
                        m('table.table', { width: '100%' }, [
                          m('tr', [
                            m('th', 'Subject'),
                            m('td', preview.rendered.email.subject)
                          ]),
                          m('tr', [
                            m('th', 'Preheader'),
                            m('td', preview.rendered.email.preheader)
                          ])
                        ]),
                        m('.box', { style: 'padding: 0;' }, m('iframe', {
                          srcdoc: preview.rendered.email.html,
                          style: {
                            width: '100%',
                            height: '900px'
                          }
                        }))
                      ])
                      : (item.error ? m(Colored, { text: yaml.safeDump(item.error), type: 'yaml' }) : m('div', 'Loading preview ..'))
                  ] : m('div', 'No email definition'),
                  m('.level', { style: 'margin-top: 2em;' }, [
                    m('.level-left', [
                      m('.level-item', [
                        m('.title.is-4', 'Locales')
                      ])
                    ]),
                    m('.level-right', [
                      m('a.button.level-item', { href: `https://github.com/Dayswaps/locales/edit/master/src/${this.language}/messages.yaml`, target: '_blank' }, [
                        m('span.icon', m('i', { class: 'fas fa-edit' })),
                        m('span', 'Edit locales')
                      ])
                    ])
                  ]),
                  pageStore.locales ? m('div', m(Colored, { text: yaml.safeDump(msgLocales(item.type, this.language) || null), type: 'yaml' })) : m('div', 'Loading locales ..'),
                  m('.level', { style: 'margin-top: 2em;' }, [
                    m('.level-left', [
                      m('.level-item', [
                        m('.title.is-4', 'Definition')
                      ])
                    ]),
                    m('.level-right', [
                      m('a.button.level-item', { href: 'https://github.com/Dayswaps/backend/edit/master/spec/messages.yaml', target: '_blank' }, [
                        m('span.icon', m('i', { class: 'fas fa-edit' })),
                        m('span', 'Edit definition')
                      ])
                    ])
                  ]),
                  m('div', m(Colored, { text: yaml.safeDump(msg), type: 'yaml' }))
                ])
              })()
              : m('div', 'Please select a message in the list on the left ..'))
          ])
        ])
      ])
    } else {
      content = spec.syntax !== false
        ? m(Colored, { text: typeof pageStore.spec === 'string' ? pageStore.spec : yaml.safeDump(pageStore.spec), type: spec.syntax })
        : m('pre.code', typeof pageStore.spec === 'string' ? pageStore.spec : yaml.safeDump(pageStore.spec))
    }

    return m('div', { style: 'margin-bottom: 2em;' }, [
      m('.title.is-3', `Spec → ${spec.name}`),
      content
    ])
  }
}
