
module.exports = [
  {
    name: 'production',
    tag: 'live2',
    color: 'hsl(219,79%,48%)',
    api: 'https://api.dayswaps.com/2/admin/',
    apiPublic: 'https://api.dayswaps.com/2/',
    fe: [
      'https://app.dayswaps.com',
      'https://app.dayswaps.top'
    ],
    host: 'admin.dayswaps.com',
    href: 'https://admin.dayswaps.com/'
  },
  {
    name: 'stage',
    tag: 'stage2',
    color: '#FC3E20',
    api: 'https://api.stage.dayswaps.top/2/admin/',
    apiPublic: 'https://api.stage.dayswaps.top/2/',
    fe: ['https://app.stage.dayswaps.top'],
    host: 'admin.stage.dayswaps.top',
    href: 'https://admin.stage.dayswaps.top/'
  },
  {
    name: 'dev',
    tag: 'dev2',
    color: '#5B4EBE',
    api: 'https://api.dev.dayswaps.top/2/admin/',
    apiPublic: 'https://api.dev.dayswaps.top/2/',
    fe: ['https://app.dev.dayswaps.top'],
    host: 'admin.dev.dayswaps.top',
    href: 'https://admin.dev.dayswaps.top/'
  },
  {
    name: 'local',
    tag: 'local2',
    color: 'hsl(0, 0%, 21%)',
    api: 'http://localhost:3002/',
    apiPublic: 'http://localhost:3001/2/',
    fe: ['http://app.local.dayswaps.top'],
    host: 'admin.local.dayswaps.top',
    href: 'http://admin.local.dayswaps.top/'
  }
]
