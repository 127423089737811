const m = require('mithril')
const data = require('../lib/data')
const cols = require('../schema/collections')

const pageStore = {}
function loadPageStore () {
  data.dbCollections({ cols: Object.keys(cols) }).then(res => {
    pageStore.dbCollections = res.data.dbCollections
    console.log(pageStore)
    m.redraw()
  })
}

module.exports = {
  oninit () {
    loadPageStore()
  },
  view (vnode) {
    return m('div', [
      m('.title.is-3', [m('i', { class: 'fas fa-database' }), 'Database']),
      m('div', pageStore.dbCollections ? m('div', [
        m('table.table', { style: 'width: 100%' }, [
          m('thead', m('tr', [
            m('th', 'collection'),
            m('th', 'name'),
            m('th', 'count'),
            m('th', 'indexes'),
            m('th', 'avg. object size'),
            m('th', 'total size')
          ])),
          m('tbody', Object.keys(cols).map(col => {
            const data = pageStore.dbCollections.find(c => c.id === col)
            return m('tr', [
              m('td', m('b', m(m.route.Link, { href: `/db/${col}` }, col))),
              m('td', cols[col].title),
              m('td', data ? data.stats.count : ''),
              m('td', data ? m('small', data.stats.nindexes) : ''),
              m('td', data ? m('small', (Math.round(data.stats.avgObjSize * 1e-3 * 100) / 100) + ' KB') : ''),
              m('td', data ? m('small', (Math.round(data.stats.size * 1e-6 * 100) / 100) + ' MB') : '')
            ])
          }))
        ])
      ]) : m('div', 'Loading data ..')),
      m('div', { style: { 'margin-bottom': 60 } })
    ])
  }
}
